<template>
  <modal :show="showModal" modal-classes="elite-modal add-file" size="lg">
    <div>
      <el-form
        @submit.prevent="checkContractInformation"
        @keydown.enter.prevent="() => {}"
        :model="contractFile"
        label-position="top"
        ref="contractFileForm"
      >
      <!-- is_template ? -->
          <el-form-item
            class="form-group-wrapper-item w-100 mb-3"
            prop="excerpt"
          >
          <el-checkbox v-model="contractFile.is_template" border>
            {{ $t("CONTRACTS.ADD_TEMPLATE_DOCUMENT") }}
          </el-checkbox>
        </el-form-item>

          <dropzone-file-upload
          v-if="!contractFile.is_template"
            :key="renderKey"
            v-model="filesModel"
            :url="uploadUrl"
            multiple
            @error="onUploadError"
          >
          </dropzone-file-upload>
          
        <div v-if="filesModel.length > 0 || contractFile.is_template">
          <!-- Name -->
          <el-form-item
            class="form-group-wrapper-item w-100"
            :label="`${$t('COMMON.NAME')} (*)`"
            prop="name"
            :rules="[
              {
                required: true,
                message: $t('CONTRACTS.NAME_REQUIRED'),
              },
            ]"
          >
            <base-input class="form-wrapper">
              <el-input
                :placeholder="$t('COMMON.NAME')"
                v-model="contractFile.name"
              />
            </base-input>
          </el-form-item>

          <!-- TEMPLATE_NAME -->
          <el-form-item
          v-if="contractFile.is_template"
            class="form-group-wrapper-item w-100"
            :label="`${$t('CONTRACTS.TEMPLATE_NAME')} (*)`"
            prop="template_name"
            :rules="[
              {
                required: true,
                message: $t('CONTRACTS.TEMPLATE_NAME_REQUIRED'),
              },
            ]"
          >
            <base-input class="form-wrapper">
              <el-input
                :placeholder="$t('CONTRACTS.TEMPLATE_NAME')"
                v-model="contractFile.template_name"
              />
            </base-input>
          </el-form-item>

          <!-- Page de signature -->
          <el-form-item
            class="form-group-wrapper-item w-100"
            :label="`${$t('CONTRACTS.SIGNATURE_PAGE')} (*)`"
            prop="signature_page"
            :rules="[
              {
                required: true,
                message: $t('CONTRACTS.SIGNATURE_PAGE_REQUIRED'),
              },
            ]"
          >
            <base-input class="form-wrapper">
              <el-input
                :placeholder="$t('CONTRACTS.SIGNATURE_PAGE')"
                v-model="contractFile.signature_page"
              />
            </base-input>
          </el-form-item>

          <!-- Excerpt -->
          <el-form-item
            class="form-group-wrapper-item w-100"
            :label="`${$t('COMMON.EXCERPT')}`"
            prop="excerpt"
          >
            <base-input :placeholder="$t('COMMON.EXCERPT')">
              <html-editor v-model="contractFile.excerpt"> </html-editor>
            </base-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <template slot="footer">
      <button
        type="submit"
        class="btn add"
        @click="checkContractInformation"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("CONTRACTS.ADD_FILES") }}
      </button>

      <button
        type="button"
        class="btn close btn-link ml-auto"
        @click="closeModal()"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import { Form, FormItem, Button, Checkbox } from "element-ui";
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import defaultContractFile from "../defaultContractFile";

const apiUrl = process.env.VUE_APP_API_BASE_URL;

export default {
  components: {
    HtmlEditor,
    DropzoneFileUpload,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Checkbox.name]: Checkbox,
  },

  mixins: [],

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    contract: {
      type: Object,
      default: null,
      description: "Files linked contract",
    },
  },

  computed: {
    uploadUrl() {
      return `${apiUrl}/uploads/${this.contract.type}/${this.contract.id}/files`;
    },
  },

  watch: {},

  data() {
    return {
      loading: false,
      filesModel: [],
      tags: [],
      excerpt: "",
      renderKey: 0,
      contractFile: cloneDeep(defaultContractFile),
    };
  },

  methods: {
    checkContractInformation() {
      this.$refs["contractFileForm"].validate(async (valid) => {
        if (valid) {
          if (this.contract.name) {
            this.addFiles();
          }
        }
      });
    },
    closeModal(refresh = false) {
      this.filesModel = [];
      this.$emit("onCloseModal", refresh);
    },

    async addFiles() {
      this.loading = true;
      let contract = {
        id: this.contract.id,
        type: "contracts",
      };

      this.contractFile.contract = contract;

      if(!this.contractFile.is_template){
      for (const file of this.filesModel) {
        const fileData = cloneDeep(this.contractFile);
        if (fileData.name == null) {
          fileData.name = file.upload.filename;
        }
        fileData.path = JSON.parse(file.xhr.response).path;
        try {
          await this.$store.dispatch("contractFiles/add", fileData);
        } catch (error) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
          this.loading = false;
          return;
        }
      }
    }else{
      const fileData = cloneDeep(this.contractFile);
        try {
          await this.$store.dispatch("contractFiles/add", fileData);
        } catch (error) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
          this.loading = false;
          return;
        }
    }
      this.$notify({
        type: "success",
        message: this.$t("FILES.FILE_ADDED"),
      });
      this.closeModal(true);
      this.loading = false;
    },

    async onUploadError(error) {
      if (error.status === "error") {
        if (error.xhr) {
          let errorMessage = this.$t("ERRORS.FILE_UPLOAD_ERROR");
          if (error.xhr.response) {
            errorMessage = error.xhr.response;
          } else {
            errorMessage = error.xhr.statusText;
          }
          await swal.fire({
            title: this.$t(`ERRORS.FILE_UPLOAD_ERROR`),
            html: errorMessage,
            icon: "error",
            customClass: {
              popup: "delete-popup",
            },
            confirmButtonText: this.$t("COMMON.OK"),
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-warning",
          });
          this.renderKey = this.renderKey + 1;
        }
      }
      this.loading = false;
    },
  },

  watch: {
    showModal(showModal) {
      this.renderKey = this.renderKey + 1;
    },
  },
};
</script>

<template>
  <modal :show="showModal" size="lg" modal-classes="elite-modal edit-file">
    <div class="elite-modal-form w-100">
      <el-form
      class="add-form"
        @submit.prevent="checkContractInformation"
        @keydown.enter.prevent="() => {}"
        :model="contractFile"
        label-position="top"
        ref="contractFileForm"
      >
      <!-- Name -->
          <el-form-item
            class="form-group-wrapper-item w-100"
            :label="`${$t('COMMON.NAME')} (*)`"
            prop="name"
            :rules="[
              {
                required: true,
                message: $t('CONTRACTS.NAME_REQUIRED'),
              },
            ]"
          >
            <base-input>
              <el-input
                :placeholder="$t('COMMON.NAME')"
                v-model="contractFile.name"
              />
            </base-input>
          </el-form-item>
          <!-- TEMPLATE_NAME -->
          <el-form-item
          v-if="contractFile.is_template"
            class="form-group-wrapper-item w-100"
            :label="`${$t('CONTRACTS.TEMPLATE_NAME')} (*)`"
            prop="template_name"
            :rules="[
              {
                required: true,
                message: $t('CONTRACTS.TEMPLATE_NAME_REQUIRED'),
              },
            ]"
          >
            <base-input>
              <el-input
                :placeholder="$t('CONTRACTS.TEMPLATE_NAME')"
                v-model="contractFile.template_name"
              />
            </base-input>
          </el-form-item>
          <!-- Page de signature -->
          <el-form-item
            class="form-group-wrapper-item w-100"
            :label="`${$t('CONTRACTS.SIGNATURE_PAGE')} (*)`"
            prop="signature_page"
            :rules="[
              {
                required: true,
                message: $t('CONTRACTS.SIGNATURE_PAGE_REQUIRED'),
              },
            ]"
          >
            <base-input>
              <el-input
                :placeholder="$t('CONTRACTS.SIGNATURE_PAGE')"
                v-model="contractFile.signature_page"
              />
            </base-input>
          </el-form-item>

          <!-- Excerpt -->
          <el-form-item
            :label="$t('COMMON.EXCERPT')"
            class="form-group-wrapper-item w-100"
            prop="excerpt"
          >
          <base-input
            :placeholder="$t('COMMON.EXCERPT')"
          >
            <html-editor v-model="contractFile.excerpt"> </html-editor>
          </base-input>
        </el-form-item>
      </el-form>
    </div>
    <template slot="footer">
      <button
        type="submit"
        class="btn edit"
        @click="checkContractInformation"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("CONTRACTS.EDIT_FILE") }}
      </button>
      <button
        type="button"
        class="btn close ml-auto"
        @click="closeModal()"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import {
  Form,
  FormItem,
  Button
} from "element-ui";
import { cloneDeep } from "lodash";
import HtmlEditor from "@/components/Inputs/HtmlEditor";

const apiUrl = process.env.VUE_APP_API_BASE_URL;

export default {
  components: {
    HtmlEditor,
    [Button.name]: Button,
    [FormItem.name]: FormItem,
    [Form.name]: Form,
  },

  mixins: [],

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    fileData: {
      type: Object,
      default: null,
      description: "File data",
    },
  },

  computed: {
    uploadUrl() {
      return `${apiUrl}/uploads/${this.object.type}/${this.object.id}/files`;
    },
  },

  watch: {},

  data() {
    return {
      loading: false,
      contractFile: cloneDeep(this.fileData),
    };
  },

  methods: {
    closeModal(refresh = false) {
      this.$emit("onCloseModal", refresh);
    },

    checkContractInformation() {
      this.$refs["contractFileForm"].validate(async (valid) => {
        if (valid) {
          if (this.contractFile.name) {
            this.editFile();
          }
        }
      });
    },

    async editFile() {
      this.loading = true;
      const fileData = cloneDeep(this.contractFile);
      try {
        await this.$store.dispatch("contractFiles/update", fileData);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
        return;
      }

      this.$notify({
        type: "success",
        message: this.$t("FILES.FILE_UPDATED"),
      });
      this.closeModal(true);
      this.loading = false;
    },
  },

  watch: {
    fileData(fileData) {
      console.log('filemodel===========',fileData);
      this.contractFile = cloneDeep(fileData);
    },
  },
};
</script>

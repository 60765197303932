var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"show":_vm.showModal,"size":"lg","modal-classes":"elite-modal edit-file"}},[_c('div',{staticClass:"elite-modal-form w-100"},[_c('el-form',{ref:"contractFileForm",staticClass:"add-form",attrs:{"model":_vm.contractFile,"label-position":"top"},on:{"submit":function($event){$event.preventDefault();return _vm.checkContractInformation.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('el-form-item',{staticClass:"form-group-wrapper-item w-100",attrs:{"label":`${_vm.$t('COMMON.NAME')} (*)`,"prop":"name","rules":[
            {
              required: true,
              message: _vm.$t('CONTRACTS.NAME_REQUIRED'),
            },
          ]}},[_c('base-input',[_c('el-input',{attrs:{"placeholder":_vm.$t('COMMON.NAME')},model:{value:(_vm.contractFile.name),callback:function ($$v) {_vm.$set(_vm.contractFile, "name", $$v)},expression:"contractFile.name"}})],1)],1),(_vm.contractFile.is_template)?_c('el-form-item',{staticClass:"form-group-wrapper-item w-100",attrs:{"label":`${_vm.$t('CONTRACTS.TEMPLATE_NAME')} (*)`,"prop":"template_name","rules":[
            {
              required: true,
              message: _vm.$t('CONTRACTS.TEMPLATE_NAME_REQUIRED'),
            },
          ]}},[_c('base-input',[_c('el-input',{attrs:{"placeholder":_vm.$t('CONTRACTS.TEMPLATE_NAME')},model:{value:(_vm.contractFile.template_name),callback:function ($$v) {_vm.$set(_vm.contractFile, "template_name", $$v)},expression:"contractFile.template_name"}})],1)],1):_vm._e(),_c('el-form-item',{staticClass:"form-group-wrapper-item w-100",attrs:{"label":`${_vm.$t('CONTRACTS.SIGNATURE_PAGE')} (*)`,"prop":"signature_page","rules":[
            {
              required: true,
              message: _vm.$t('CONTRACTS.SIGNATURE_PAGE_REQUIRED'),
            },
          ]}},[_c('base-input',[_c('el-input',{attrs:{"placeholder":_vm.$t('CONTRACTS.SIGNATURE_PAGE')},model:{value:(_vm.contractFile.signature_page),callback:function ($$v) {_vm.$set(_vm.contractFile, "signature_page", $$v)},expression:"contractFile.signature_page"}})],1)],1),_c('el-form-item',{staticClass:"form-group-wrapper-item w-100",attrs:{"label":_vm.$t('COMMON.EXCERPT'),"prop":"excerpt"}},[_c('base-input',{attrs:{"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{model:{value:(_vm.contractFile.excerpt),callback:function ($$v) {_vm.$set(_vm.contractFile, "excerpt", $$v)},expression:"contractFile.excerpt"}})],1)],1)],1)],1),_c('template',{slot:"footer"},[_c('button',{staticClass:"btn edit",attrs:{"type":"submit","disabled":_vm.loading},on:{"click":_vm.checkContractInformation}},[(_vm.loading)?_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-spinner fa-spin"})]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("CONTRACTS.EDIT_FILE"))+" ")]),_c('button',{staticClass:"btn close ml-auto",attrs:{"type":"button","disabled":_vm.loading},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CLOSE"))+" ")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }